<template>
  <n-grid :cols="1" class="footer" :class="isiOSPlatform ? 'footer-ios' : ''">
    <n-gi v-if="haveDetails">
      <n-space justify="end" size="small">
        <div style="padding-botton: 2px">
          {{ t("publications.footerSection.mobileView.title") }}
        </div>
        <n-button
          text
          @click="() => (showDetailedInfo = !showDetailedInfo)"
          class="pr-2 pt-1"
        >
          <template #icon>
            <n-icon>
              <UpIcon v-if="!showDetailedInfo" />
              <DownIcon v-else />
            </n-icon>
          </template>
        </n-button>
      </n-space>
    </n-gi>
    <n-gi v-if="showResume">
      <div class="content pl-2 pr-2">
        <n-collapse-transition :show="!showDetailedInfo">
          <slot></slot>
        </n-collapse-transition>
      </div>
    </n-gi>
  </n-grid>

  <n-drawer
    v-if="haveDetails"
    v-model:show="showDetailedInfo"
    placement="bottom"
    :height="detailDrawerHeight"
  >
    <n-drawer-content>
      <n-grid :cols="1">
        <n-gi>
          <n-space justify="end" size="small">
            <n-button
              text
              @click="() => (showDetailedInfo = !showDetailedInfo)"
              class="pr-2 pt-2"
            >
              <template #icon>
                <n-icon>
                  <UpIcon v-if="!showDetailedInfo" />
                  <DownIcon v-else />
                </n-icon>
              </template>
            </n-button>
          </n-space>
        </n-gi>
        <n-gi>
          <div class="content pl-2 pr-2">
            <n-collapse-transition :show="showDetailedInfo">
              <slot></slot>
            </n-collapse-transition>
          </div>
        </n-gi>
      </n-grid>
    </n-drawer-content>
  </n-drawer>
</template>

<style lang="scss" scoped>
.footer {
  position: fixed;
  left: 0;
  bottom: 0px;
  width: 100%;
  z-index: 100;
  background: white;
  padding-top: unset;
  text-align: left;
}
.content {
  height: 70px;
}
:deep(.n-drawer-body-content-wrapper) {
  padding: unset !important;
  text-align: left;
}

.footer-ios {
  @mixin supports-safe-area-insets {
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
      @content;
    }
  }
  @include supports-safe-area-insets {
    height: calc(env(safe-area-inset-bottom) + 25px);
  }
}
</style>
<script>
import { defineComponent, watch, ref, computed } from "vue";
import {
  NDrawer,
  NDrawerContent,
  NButton,
  NIcon,
  NGrid,
  NGi,
  NSpace,
  NCollapseTransition,
} from "naive-ui";
import {
  ArrowUpCircleOutline as UpIcon,
  ArrowDownCircleOutline as DownIcon,
} from "@vicons/ionicons5";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default defineComponent({
  name: "FooterMobile",
  props: {
    showResume: {
      type: Boolean,
      default: false,
    },
    haveDetails: {
      type: Boolean,
      default: false,
    },
    detailDrawerHeight: {
      type: String,
      default: "251",
    },
  },
  components: {
    NDrawer,
    NDrawerContent,
    NButton,
    UpIcon,
    DownIcon,
    NIcon,
    NGrid,
    NGi,
    NSpace,
    NCollapseTransition,
  },

  emits: ["expand"],
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const store = useStore();
    const isiOSPlatform = computed(() => store.state.app.isiOS);
    const showDetailedInfo = ref(false);
    watch(showDetailedInfo, (showDetailedInfo) => emit("expand", showDetailedInfo));
    return {
      t,
      showDetailedInfo,
      isiOSPlatform,
    };
  },
});
</script>
