<template>
  <n-grid cols="1 s:8" responsive="screen" style="padding-top: 30px">
    <n-gi span="1 s:5" offset="0 s:1">
      <n-grid cols="4 s:8" responsive="screen">
        <n-gi span="2" offset="2 s:6">
          <n-form label-placement="left">
            <n-form-item :label="`${t(commons.labels.filter, 1)}:`">
              <n-select v-model:value="filter" :options="filterOptions" />
            </n-form-item>
          </n-form>
        </n-gi>
      </n-grid>
      <Questions v-if="publication" :publication="publication" :filter="filter" />
    </n-gi>
    <n-gi span="0 s:2">
      <div>
        <publication-resumen
          v-if="publication"
          :publication="publication"
          showDeliveryInfo
          showLocation
          showPrice
          vertical
          showDefaultImage
        />
      </div>
    </n-gi>
  </n-grid>
  <footer-mobile
    v-if="isMobile"
    haveDetails
    :detailDrawerHeight="`${(parseInt(windowsHeight) - 80).toString()}px`"
    :showResume="false"
  >
    <publication-resumen
      v-if="publication"
      :publication="publication"
      :bordered="false"
      showDeliveryInfo
      showLocation
      showPrice
      vertical
      showDefaultImage
    />
  </footer-mobile>
</template>
<style lang="scss" scoped>
.n-grid {
  text-align: left;
}
</style>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { NGrid, NGi, NSelect, NFormItem, NForm } from "naive-ui";
import Questions from "@/components/publication/questions/Main.vue";
import PublicationResumen from "@/components/publication/PublicationResumen.vue";
import { useStore } from "vuex";
import responsive from "@/mixins/responsive";
import FooterMobile from "@/components/FooterMobile.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    NGrid,
    NGi,
    Questions,
    PublicationResumen,
    FooterMobile,
    NSelect,
    NFormItem,
    NForm,
  },
  props: {
    publicationId: { type: String, required: true },
  },
  mixins: [responsive],
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const store = useStore();
    const publicationRef = ref(null);
    const filterRef = ref("all");

    onMounted(async () => {
      await store.dispatch("app/lockUI");
      publicationRef.value = await store.dispatch(
        "maintainer_publication/getResumenPublicationById",
        props.publicationId
      );
      await store.dispatch("app/unlockUI");
    });
    return {
      t,
      publication: publicationRef,
      filter: filterRef,
      filterOptions: [
        {
          label: t("commons.labels.all"),
          value: "all",
        },
        {
          label: t("commons.labels.pending"),
          value: "pending",
        },
      ],
    };
  },
});
</script>
